.menuGaucheSubMenuListItemButton {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 24px !important;
}

.menuGaucheSubMenuListItemButton .MuiListItemButton-root {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.menuGaucheList .MuiListItemText-root {
    padding-left: 2px;
}

/*
.menuGaucheList .MuiTypography-root {
    font-size: 14px !important;
}

.menuGaucheList .MuiSvgIcon-root {
    font-size: 16px !important;
}
*/